<template>
	<Modal
		class="view-project-modal"
		:value="visible"
		fullscreen
		:mask-closable="false"
		:closable="false"
		width="1150"
	>
		<Spin fix v-if="loading"></Spin>
		<div slot="header" class="view-pro-modal-header">
			<h2>查看项目——{{ projectTypeName }}</h2>
			<span
				v-if="projectInfo.terminateDate"
				style="
					color: red;
					float: right;
					line-height: 20px;
					margin-right: 20px;
					font-weight: 700;
				"
				>项目已终止</span
			>
		</div>
		<design-template-view
			:componentList="componentList"
			:projectInfo="projectInfo"
			ref="designTemplate_add_project"
		></design-template-view>
		<div v-if="!isXinhua">
			<div class="genetic-wrapper" v-if="isOpenHgc">
				<h2>遗传办信息</h2>
			</div>
			<Checkbox v-model="isOpenHgc" disabled :true-value="1" :false-value="0">
				是否创建遗传办文档
			</Checkbox>
			<!--<design-template-view v-if="isOpenHgc"-->
			<!--:componentList="hgcComponentList"-->
			<!--ref="designTemplate_view_genetic"></design-template-view>-->
		</div>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">关闭 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/project"
import designTemplateView from "@/components/BusinessComponents/FormDesign/designTemplateView.vue"
import { mapState } from "vuex"

const { apiGet } = api
export default {
	name: "viewProject",
	components: {
		designTemplateView
	},
	data() {
		return {
			componentList: [],
			hgcComponentList: [],
			projectTypeName: "",
			loading: false,
			visible: true,
			updateId: this.$route.params.proId,
			formData: {},
			title: "",
			isResize: false,
			orgId: localStorage.getItem("orgId"),
			isOpenHgc: 0,
			projectInfo: {}
		}
	},
	computed: {
		conHeight() {
			return this.isResize ? document.body.clientHeight - 145 : 455
		},
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		},
		...mapState(["enumerate"])
	},
	created() {
		// 获取所有枚举
		if (!Object.keys(this.enumerate).length) {
			this.$store.dispatch("getEnumerate")
		}
	},
	mounted() {
		window.onresize = () => {
			this.isResize = !this.isResize
		}
		this.$nextTick(() => {
			this.initProject()
		})
	},
	methods: {
		initProject() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGet({ id: this.updateId, isDealAcronym: 1 })
				if (res && res.data && res.data.project) {
					this.projectInfo = res.data.project
					if (res.data.project.jsonData) {
						this.componentList = JSON.parse(res.data.project.jsonData)
					}
					if (res.data.project.jsonDataAcronym) {
						const jsonDataAcronym = JSON.parse(res.data.project.jsonDataAcronym)
						this.componentList = [...this.componentList, ...jsonDataAcronym]
					}
					this.isOpenHgc = res.data.project.isOpenHgc
					if (res.data.project.jsonDataHgc) {
						this.hgcComponentList = JSON.parse(res.data.project.jsonDataHgc)
					}
					this.projectTypeName = res.data.project.projectConfigName
				}
				this.loading = false
			})
		},
		onCancel() {
			window.close()
		}
	}
}
</script>

<style lang="less">
.view-project-modal {
	.con {
		/*height: 600px;*/
		overflow: auto;
	}
	.title {
		font-size: 15px;
		font-weight: bold;
		padding: 16px 0;
		border-top: dashed 1px #515a6e;
	}

	.mrgin0 {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.view-pro-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.genetic-wrapper {
		margin-left: 25px;
		h2 {
			font-weight: bolder;
			padding-bottom: 5px;
		}
	}
}
</style>
