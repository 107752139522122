import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/auth/department"

export default {
	...commonApi(moduleName),
  apiGetDeptTree: data =>
    request('/auth/dept/tree', { // 查询获取全部科室列表
      method: "get",
      params: data
    }),
    apiGetDeptAvailableTree: data =>
      request('/auth/dept/available-Tree', { // 查询获取未被禁用科室列表
        method: "get"
      }),
    apiGetDeptDeleteTree: data =>
      request('/auth/dept/delete-Tree', { // 查询获取禁用科室列表
        method: "get"
      }),
  apiInsertDept: data =>
    request('/auth/dept/insert', { // 新增科室列表
      method: "post",
      body: data
    }),
  apiUpdateDept: data =>
    request('/auth/dept/update', { // 修改科室列表
      method: "post",
      body: data
    }),
  apiDelDept: data =>
    request(`/auth/dept/${data.deptId}`, { // 删除科室列表
      method: "delete"
    }),
    apiEnableDept: data =>
      request(`/auth/dept/${data.deptId}/available`, { // 启用科室列表
        method: "post"
      }),
    
    
}
